<template>
    <div>
        <users-form :entity-id="userId"></users-form>
    </div>
</template>

<script>
import UsersForm from '@/components/pages/users/Form'
import dirtyForm from '@/components/mixins/dirtyForm'

export default {
    components: { UsersForm },

    mixins: [dirtyForm],

    props: {
        userId: {
            type: [String, Number],
            default: null,
        },
    },
}
</script>
