<template>
    <div class="w-100">
        <!-- GENERAL -->
        <form-panel>
            <form-two-col-row>
                <template v-slot:col1>
                    <text-input
                        v-model="form.firstName"
                        v-validate="'required|min:2|max:50|space'"
                        v-tab-error:general
                        name="firstName"
                        label="labels.first_name"
                        :error-messages="errors.collect('firstName')"
                        :data-vv-as="$t('labels.first_name')"
                    ></text-input>
                </template>
                <template v-slot:col2>
                    <text-input
                        v-model="form.lastName"
                        v-validate="'required|min:2|max:50|space'"
                        v-tab-error:general
                        name="lastName"
                        label="labels.last_name"
                        autocomplete="new-password"
                        :error-messages="errors.collect('lastName')"
                        :data-vv-as="$t('labels.last_name')"
                    ></text-input>
                </template>
            </form-two-col-row>

            <form-two-col-row>
                <template v-slot:col1>
                    <text-input
                        v-model="form.email"
                        v-validate="'required|email|min:3|max:150|space'"
                        v-tab-error:general
                        :disabled="!!form.id"
                        name="email"
                        type="email"
                        label="labels.email"
                    ></text-input>
                </template>
                <template v-slot:col2>
                    <password-input
                        v-model="form.plainPassword"
                        v-validate="
                            form['@id']
                                ? 'min:6|max:50'
                                : 'required|min:6|max:50'
                        "
                        v-tab-error:general
                        name="plainPassword"
                        label="labels.password"
                        :error-messages="errors.collect('plainPassword')"
                        :data-vv-as="$t('labels.password')"
                        autocomplete="new-password"
                    ></password-input>
                </template>
            </form-two-col-row>

            <form-two-col-row>
                <template v-slot:col1>
                    <text-input
                        v-model="form.phone"
                        v-validate="'required|phonenumber'"
                        v-tab-error:general
                        name="phone"
                        label="labels.phone"
                        :error-messages="errors.collect('phone')"
                        :data-vv-as="$t('labels.phone')"
                        @blur="form.phone = formatPhoneNumber(form.phone)"
                    ></text-input>
                </template>
                <template v-slot:col2>
                    <v-row v-if="isSuperAdmin">
                        <v-col sm="6" class="py-0">
                            <text-input
                                v-model.number="form.acceptedTermsVersion"
                                v-validate="
                                    `required|min_value:1|max_value:${latestTermsVersion}|integer`
                                "
                                v-tab-error:general
                                name="acceptedTermsVersion"
                                type="number"
                                label="labels.accepted_terms_version"
                                :error-messages="
                                    errors.collect('acceptedTermsVersion')
                                "
                                :data-vv-as="
                                    $t('labels.accepted_terms_version')
                                "
                            ></text-input>
                        </v-col>
                        <v-col sm="6" class="py-0">
                            <text-input
                                v-model.number="form.acceptedPrivacyVersion"
                                v-validate="
                                    `required|min_value:1|max_value:${latestPrivacyVersion}|integer`
                                "
                                v-tab-error:general
                                name="acceptedPrivacyVersion"
                                type="number"
                                label="labels.accepted_privacy_version"
                                :error-messages="
                                    errors.collect('acceptedPrivacyVersion')
                                "
                                :data-vv-as="
                                    $t('labels.accepted_privacy_version')
                                "
                            ></text-input>
                        </v-col>
                    </v-row>
                </template>
            </form-two-col-row>

            <form-two-col-row>
                <template v-slot:col1>
                    <checkbox-input
                        v-model="form.enabled"
                        v-validate="{}"
                        v-tab-error:general
                        name="active"
                        label="labels.active"
                        :error-messages="errors.collect('active')"
                        :data-vv-as="$t('labels.active')"
                    ></checkbox-input>
                </template>
            </form-two-col-row>
        </form-panel>

        <!-- COMPANY SETTINGS -->
        <form-panel :title="$t('labels.company_settings')">
            <form-three-col-row>
                <template v-slot:col1>
                    <entity-select-input
                        v-if="isSuperAdmin"
                        v-model="form.company"
                        v-validate="{}"
                        v-tab-error:general
                        label="labels.member_of_the_company"
                        :data-vv-as="$t('labels.member_of_the_company')"
                        :entity="selectApiClientType.COMPANY"
                        name="memberOfCompany"
                        autocomplete
                        :error-messages="errors.collect('company')"
                    ></entity-select-input>
                </template>
                <template v-slot:col2>
                    <entity-select-input
                        v-show="form.company"
                        v-model="form.allowances"
                        v-validate="{}"
                        v-tab-error:general
                        name="allowances"
                        label="labels.ordering_rules"
                        type="checkbox"
                        :data-vv-as="$t('labels.ordering_rules')"
                        :entity="selectApiClientType.COMPANY_ALLOWANCE"
                        multi-select
                        :columns="2"
                        :query-params="{
                            company: form.company,
                            withoutCompanyTeam: 1,
                        }"
                        :request-disabled="form.company === null"
                        :error-messages="errors.collect('allowances')"
                    ></entity-select-input>
                </template>
            </form-three-col-row>
        </form-panel>

        <!-- ROLES -->
        <form-panel v-if="isSuperAdmin" :title="$t('labels.roles')">
            <form-three-col-row>
                <template v-slot:col1>
                    <entity-select-input
                        v-model="form.adminOfCompany"
                        v-validate="{}"
                        v-tab-error:general
                        label="labels.admin_of_the_company"
                        :data-vv-as="$t('labels.admin_of_the_company')"
                        name="adminOfCompany"
                        :entity="selectApiClientType.COMPANY"
                        autocomplete
                        :error-messages="errors.collect('adminOfCompany')"
                        :disabled="
                            !!(
                                form.isSuperAdmin ||
                                (form.adminOfRestaurant &&
                                    form.adminOfRestaurant.length)
                            )
                        "
                    ></entity-select-input>
                </template>
                <template v-slot:col2>
                    <entity-select-input
                        v-model="form.adminOfRestaurant"
                        v-validate="{}"
                        v-tab-error:general
                        name="adminOfRestaurant"
                        label="labels.admin_of_the_restaurant"
                        :data-vv-as="$t('labels.admin_of_the_restaurant')"
                        :entity="selectApiClientType.RESTAURANT"
                        autocomplete
                        multi-select
                        :error-messages="errors.collect('adminOfRestaurant')"
                        :disabled="!!(form.isSuperAdmin || form.adminOfCompany)"
                    ></entity-select-input>
                </template>
                <template v-slot:col3>
                    <checkbox-input
                        v-model="form.isSuperAdmin"
                        v-validate="{}"
                        v-tab-error:general
                        name="superAdmin"
                        label="labels.super_admin"
                        :error-messages="errors.collect('superAdmin')"
                        :data-vv-as="$t('labels.super_admin')"
                    >
                    </checkbox-input>
                </template>
            </form-three-col-row>
            <form-two-col-row>
                <template v-slot:col1>
                    <entity-select-input
                        v-model="form.extraRoles"
                        v-validate="{}"
                        v-tab-error:general
                        label="labels.extra_roles"
                        :data-vv-as="$t('labels.extra_roles')"
                        name="extraRoles"
                        :entity="selectApiClientType.USER_EXTRA_ROLES"
                        autocomplete
                        :error-messages="errors.collect('extraRoles')"
                        :disabled="!!form.isSuperAdmin"
                        multi-select
                        translate-items
                    ></entity-select-input>
                </template>
            </form-two-col-row>
        </form-panel>

        <!-- COMPANY TEAMS -->
        <form-panel
            v-if="(isSuperAdmin || adminOfCompany) && form.companyId"
            :title="$t('labels.company_teams')"
        >
            <form-one-col-row>
                <template>
                    <entity-select-input
                        v-model="form.companyTeams"
                        v-validate="{}"
                        v-tab-error:general
                        label="labels.company_teams"
                        :data-vv-as="$t('labels.company_teams')"
                        name="companyTeams"
                        :entity="selectApiClientType.COMPANY_TEAMS"
                        :query-params="{
                            companyId: form.companyId,
                        }"
                        autocomplete
                        :error-messages="errors.collect('companyTeams')"
                        multi-select
                    ></entity-select-input>
                </template>
            </form-one-col-row>
        </form-panel>

        <!-- NOTIFICATION SETTINGS -->
        <form-panel :title="$t('labels.notification_settings')">
            <form-two-col-row>
                <template v-slot:col1>
                    <entity-checkbox-input
                        v-model="form.notificationSettings"
                        v-tab-error:general
                        :entity="selectApiClientType.USER_NOTIFICATIONS"
                        translate-items
                        name="notificationSettings"
                        type="checkbox"
                    ></entity-checkbox-input>
                </template>
                <template v-slot:col2></template>
            </form-two-col-row>
        </form-panel>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import AuthMixin from '@/components/mixins/AuthMixin'
import FormPartMixin from '@/components/mixins/FormPartMixin'
import PhoneNumberMixin from '@/components/mixins/PhoneNumberMixin'
import FormTabMixin from '@/components/mixins/FormTabMixin'
import FormPanel from '@/components/form/FormPanel'
import FormOneColRow from '@/components/form/FormOneColRow'
import FormTwoColRow from '@/components/form/FormTwoColRow'
import FormThreeColRow from '@/components/form/FormThreeColRow'
import TextInput from '@/components/form/Inputs/TextInput'
import EntitySelectInput from '@/components/form/Inputs/EntitySelectInput'
import EntityCheckboxInput from '@/components/form/Inputs/EntityCheckboxInput'
import CheckboxInput from '@/components/form/Inputs/CheckboxInput'
import PasswordInput from '@/components/form/Inputs/PasswordInput'
import configType from '@/store/type/configType'
import isEmpty from 'lodash/isEmpty'

export default {
    components: {
        FormPanel,
        FormThreeColRow,
        FormTwoColRow,
        FormOneColRow,
        TextInput,
        EntitySelectInput,
        EntityCheckboxInput,
        CheckboxInput,
        PasswordInput,
    },
    mixins: [FormPartMixin, AuthMixin, PhoneNumberMixin, FormTabMixin],

    computed: {
        ...mapGetters({
            latestTermsVersion: configType.getters.LATEST_TERMS_VERSION,
            latestPrivacyVersion: configType.getters.LATEST_PRIVACY_VERSION,
        }),
    },

    watch: {
        'form.company'(company) {
            if (!company) {
                this.form.allowances = []
            }
            this.form.companyAddresses = []
        },
        'form.isSuperAdmin'(isSuperAdmin) {
            if (isSuperAdmin) {
                this.form.adminOfCompany = null
                this.form.adminOfRestaurant = []
            }
        },
        'form.adminOfCompany'(adminOfCompany) {
            if (!isEmpty(adminOfCompany)) {
                this.form.adminOfRestaurant = []
            }
        },
        'form.adminOfRestaurant'(adminOfRestaurant) {
            if (!isEmpty(adminOfRestaurant)) {
                this.form.adminOfCompany = null
            }
        },
        $route: {
            handler() {
                if (this.$route.query.restaurant) {
                    this.form.adminOfRestaurant.push(
                        this.$route.query.restaurant
                    )
                }
                if (
                    this.$route.query.company ||
                    this.$route.query.companyAdmin
                ) {
                    if (this.$route.query.companyAdmin)
                        this.$set(
                            this.form,
                            'adminOfCompany',
                            this.$route.query.companyAdmin
                        )
                    this.$set(
                        this.form,
                        'company',
                        this.$route.query.company ??
                            this.$route.query.companyAdmin
                    )
                }
            },
            immediate: true,
        },
    },
}
</script>
