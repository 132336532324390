import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'

export default {
    data() {
        return {
            tabErrorsContainer: {},
        }
    },

    directives: {
        tabError(el, binding, vNode) {
            if (!vNode.componentOptions.propsData.name) {
                console.warn('FormExtend, missing name', el)
                return
            }

            if (!vNode.componentOptions.propsData.errorMessages) {
                // @TODO errormessage-ek felvitele
                // https://vee-validate.logaretm.com/v2/guide/messages.html#field-specific-custom-messages
                // console.warn(
                //     'FormExtend, missing errorMessages for ' +
                //         vNode.componentOptions.propsData.name
                // )

                return
            }

            const inputName = vNode.componentOptions.propsData.name
            const errorCount =
                vNode.componentOptions.propsData.errorMessages.length
            const tabName = binding.arg

            const errorsClone = cloneDeep(vNode.context.tabErrorsContainer)

            if (!errorsClone[tabName]) {
                errorsClone[tabName] = {}
            }

            errorsClone[tabName][inputName] = errorCount

            if (!isEqual(errorsClone, vNode.context.tabErrorsContainer)) {
                vNode.context.tabErrorsContainer = errorsClone
                vNode.context.$emit('tabError', errorsClone)
            }
        },
    },
}
