<template>
    <div>
        <users-form></users-form>
    </div>
</template>

<script>
import UsersForm from '@/components/pages/users/Form'
import dirtyForm from '@/components/mixins/dirtyForm'
import AuthMixin from '@/components/mixins/AuthMixin'

export default {
    components: {
        UsersForm,
    },

    mixins: [dirtyForm, AuthMixin],

    mounted() {
        // if (!this.canCreateUsers) this.$router.replace('/orders')
    },
}
</script>
