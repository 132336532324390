<template>
    <div>
        <div>
            <label v-if="label">{{ $t(label) }}</label>
            <v-row>
                <v-col
                    v-for="item in selectItems"
                    :key="item.id"
                    cols="12"
                    :sm="colSm"
                    class="py-0"
                >
                    <v-switch
                        v-model="value[item.key]"
                        class="mr-4 mt-3"
                        :input-value="value[item.key]"
                        :name="`notificationSettings${[item.key]}`"
                        :label="item.label"
                        type="checkbox"
                        color="success"
                        :disabled="isInvalid(item)"
                        hide-details="auto"
                        :error="hasError"
                    ></v-switch>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
import isEqual from 'lodash/isEqual'
import cloneDeep from 'lodash/cloneDeep'
import selectapiType from '@/store/type/selectapiType'
import ErrorMsgInputMixin from './InputMixins/ErrorMsgInputMixin'

export default {
    mixins: [ErrorMsgInputMixin],
    props: {
        value: {
            type: [Number, String, Object, Array, Boolean],
            default: null,
        },
        entity: {
            type: String,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        small: {
            type: Boolean,
            default: false,
        },
        requestDisabled: {
            type: Boolean,
            default: false,
        },
        queryParams: {
            type: Object,
            default: null,
        },
        useIdField: {
            type: Boolean,
        },
        useNameField: {
            type: Boolean,
        },
        translateItems: {
            type: Boolean,
        },
        needsUpdate: {
            type: [Boolean],
        },
        errorMessages: {
            type: Array,
            default: () => {
                return []
            },
        },
        invalid: {
            type: Array,
            default: () => {
                return []
            },
        },
        columns: {
            type: Number,
            default: 1,
        },
    },
    data() {
        return {
            selectedValue: undefined,
            items: [],
            selectIsDisabled: false,
            lastQueryParams: null,
        }
    },
    computed: {
        selectItems() {
            if (this.translateItems) {
                const items = cloneDeep(this.items)

                items.forEach((item) => {
                    item.label = this.$t(item.label)
                })

                return items
            }

            return this.items
        },

        colSm() {
            return Math.floor(12 / this.columns)
        },

        hasError() {
            return this.errorMessages.length > 0
        },
        itemValue() {
            return this.useIdField ? 'id' : this.useNameField ? 'name' : 'key'
        },
    },
    watch: {
        entity: {
            immediate: true,
            handler() {
                this.updateList()
            },
        },
        queryParams() {
            if (!isEqual(this.queryParams, this.lastQueryParams)) {
                this.updateList()
            }
        },
        needsUpdate: 'updateList',
    },
    methods: {
        updateList() {
            if (this.requestDisabled) {
                return
            }

            this.selectIsDisabled = true
            this.lastQueryParams = this.queryParams

            this.$store
                .dispatch(
                    selectapiType.getActionName(
                        this.entity,
                        selectapiType.actions.LOAD
                    ),
                    { query: this.queryParams }
                )
                .then((data) => {
                    this.selectIsDisabled = false
                    this.items = data
                })
        },
        isInvalid(item) {
            if (this.invalid?.length) {
                const idx = this.invalid.indexOf(item.key)
                return idx !== -1 ? true : false
            }
            return false
        },
    },
}
</script>
<style lang="scss">
.entity-selector {
    .v-text-field {
        margin-top: 0;
        padding-top: 0;
    }
    .v-input__slot {
        transition: margin-bottom var(--transition-speed);
    }
    .v-text-field__details,
    .v-messages {
        transition: min-height var(--transition-speed);
    }
    &:not(.has-error) {
        .v-input__slot {
            margin-bottom: 0;
        }
        .v-text-field__details,
        .v-messages {
            min-height: 0;
        }
    }
    &.entity-selector-small {
        .v-select.v-text-field input {
            padding: 0;
            max-height: 20px;
            font-size: 14px;
        }
    }
}
</style>
