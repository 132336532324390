import { parseNumber, format } from 'libphonenumber-js'
import configType from '@/store/type/configType'

export default {
    methods: {
        formatPhoneNumber(value) {
            if (!value) {
                return value
            }

            const defaultCountry =
                this.$store.getters[configType.getters.CURRENCY_LOCALE]
                    .toUpperCase()
                    .split('-')[1] || 'GB'

            const parsed = parseNumber(value, {
                extended: true,
                defaultCountry,
            })

            if (parsed && parsed.valid) {
                return format(parsed.phone, parsed.country, 'International')
            } else {
                return value
            }
        },
    },
}
